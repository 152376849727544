import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
let path = "/";
/*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  path = "/";
} else {
  path = "/index.php";
}*/
console.log(path);
root.render(
  <Provider store={store}>
    <Router basename={''}>
      <Routes>
        <Route exact path={path} element={
          <React.StrictMode>
            <App />
          </React.StrictMode>
        } />
      </Routes>
    </Router>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

