import axios from 'axios';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { 
  RESET_STATE,
  SET_CALCULATED_LAYOUT,
} from "./types";
import { calc } from '../layout';


const INITIAL_STATE = {
  palette: {
    white: {main:'rgb(255,255,255)'},
    black: {main:'rgb(12,4,5)'},
    pink: {main:"rgb(248,246,247)"},
    darkPink: {main:"rgb(250,245,247)"},
    red: {main:"rgb(234,76,137)"},
    darkRed: {main:"rgb(247,9,116)"},
    deepRed: {main:"rgb(199,22,102)"},
    blue: {main:"rgb(149,223,241)"},
    yellow: {main:"rgb(253,198,0)"},
    error: {main:"red"},
    gray: {main:"rgb(200,200,200)"},
    text1: {main:"#545c6a"},
    text2: {main:"rgba(25, 118, 210, 1)"}
  },
  theme: createTheme({
    typography: {
      htmlFontSize: 16,
    },
  }),
  formatCrc: (crc) => { return crc.toLocaleString(undefined, { maximumFractionDigits: 2 })},
  lt_margin: 16,
  lt_gutter: 8,
  lt: calc(window.$(document).width(), 16, 8),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATED_LAYOUT:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return INITIAL_STATE;
  }
};